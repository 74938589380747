import {useEffect, useRef} from 'react'

type CallbackType = (args: number[] | undefined) => void

const useDebounce = (callback: CallbackType, delay: number): CallbackType => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const debouncedCallback: CallbackType = (args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      callback(args)
    }, delay)
  }

  return debouncedCallback
}

export default useDebounce
