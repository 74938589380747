import useRealtimeQuotes from '~app/(main)/my-stocks/utils/useRealtimeQuotes'
import type {RealtimeQuotesResponse, Quote} from '~types/my-stocks'
import Skeleton from 'react-loading-skeleton'
import Indicator from '~components/table/indicator'
import {useEffect, useState} from 'react'
import classNames from 'classnames'
import {ColoredChange} from '~utils/formatters/numbers'
import ScrollMask from './scroll-mask'
import {useLazyQuery} from '@apollo/client'
import {FUTURES_QUERY} from '~components/layout/query'

const staticNameToSymbol = {
  ES: 'S&P Futures',
  NQ: 'Nasdaq Futures',
  CL: 'Crude Oil Futures',
}

type MarketStatus = 'closed' | 'open'

export default function StaticTickerTape({
  tickers,
  className,
}: {
  tickers: Quote[]
  className?: string
}) {
  const [marketStatus, setMarketStatus] = useState<MarketStatus>('open')
  const [activeTickers, setActiveTickers] = useState<Quote[]>(tickers)
  const [openMarketTickers, setOpenMarketTickers] = useState<Quote[]>([])
  const [realtimeQuotes, getRealtimeQuotes] = useRealtimeQuotes({
    quotesFallback: true,
  })

  const [fetchFuturesQuery] = useLazyQuery(FUTURES_QUERY, {
    onCompleted: (data) => {
      if (!data?.futures) return

      const futuresFromGraphQL = data.futures
        .filter(
          (future): future is NonNullable<typeof future> => future !== null,
        )
        .map((future): Quote | null => {
          const name =
            staticNameToSymbol[
              future.baseSymbol as keyof typeof staticNameToSymbol
            ]

          if (!name || !future.quote?.last) return null

          return {
            name,
            price: future.quote.last,
            change: future.quote.percentChange ?? 0,
          }
        })
        .filter((future): future is Quote => future !== null)

      const bitcoin = openMarketTickers.find(
        (ticker) => ticker.name === 'Bitcoin',
      )

      const finalFutures: Quote[] = bitcoin
        ? [
            ...futuresFromGraphQL,
            {
              name: bitcoin.name || 'Bitcoin',
              price: bitcoin.price,
              change: bitcoin.change,
            },
          ]
        : futuresFromGraphQL

      setActiveTickers(finalFutures)
    },
  })

  useEffect(() => {
    const now = new Date()
    const estTime = new Date(
      now.toLocaleString('en-US', {timeZone: 'America/New_York'}),
    )
    const hours = estTime.getHours()
    const isAfter7pm = hours >= 19

    const urlParams = new URLSearchParams(window.location.search)
    const isTesting = urlParams.has('closed')

    if (isAfter7pm || isTesting) {
      setMarketStatus('closed')
      const bitcoin = tickers.find((ticker) => ticker.name === 'Bitcoin')
      if (bitcoin) {
        tickers = [bitcoin]
        const staticTickers = Object.keys(staticNameToSymbol).map((key) => ({
          name: staticNameToSymbol[key as keyof typeof staticNameToSymbol],
        }))
        setActiveTickers([...staticTickers, bitcoin])
      }
      fetchFuturesQuery()
    } else {
      setMarketStatus('open')
    }

    if (getRealtimeQuotes) {
      const instrumentIds = tickers
        .map((ticker) => ticker.instrumentId)
        .filter((id): id is number => id !== undefined)
      getRealtimeQuotes({
        instrumentIds,
      })
    }
  }, [])

  useEffect(() => {
    if (activeTickers && realtimeQuotes) {
      const tickersWithQuotes = activeTickers.map((ticker: Quote) => {
        return {
          ...ticker,
          name: ticker.name,
          price: ticker.instrumentId
            ? (realtimeQuotes as RealtimeQuotesResponse)[
                ticker.instrumentId.toString()
              ]?.price
            : null,
          change: ticker.instrumentId
            ? (realtimeQuotes as RealtimeQuotesResponse)[
                ticker.instrumentId.toString()
              ]?.change
            : null,
          indicator: ticker.instrumentId
            ? (realtimeQuotes as RealtimeQuotesResponse)[
                ticker.instrumentId.toString()
              ]?.indicator
            : null,
        }
      })

      setOpenMarketTickers(tickersWithQuotes)
      if (marketStatus === 'closed') {
        fetchFuturesQuery()
      } else {
        setActiveTickers(tickersWithQuotes)
      }
    }
  }, [realtimeQuotes])

  const formatPrice = (price: number) =>
    Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price)

  return (
    <ScrollMask className={classNames('grid', className)}>
      <div className="flex place-content-between bg-content-4 px-24px py-8px border-b-1px border-content-8 overflow-x-auto no-scrollbar">
        {activeTickers.map(({name, price, change, indicator = null}) => (
          <div
            key={name}
            className="flex gap-x-2 it px-8px items-center flex-0"
          >
            <span className="font-bold whitespace-nowrap">{name}</span>
            <span>
              {price ? (
                <Indicator indicator={indicator}>
                  {formatPrice(price)}
                </Indicator>
              ) : (
                <Skeleton
                  baseColor="#EBEDF9"
                  highlightColor="#F5F6FC"
                  width={60}
                  height={25}
                />
              )}
            </span>
            {change ? (
              <ColoredChange change={change} truncateAt={12} />
            ) : (
              <Skeleton
                baseColor="#EBEDF9"
                highlightColor="#F5F6FC"
                width={60}
                height={25}
              />
            )}
          </div>
        ))}
      </div>
    </ScrollMask>
  )
}
