export const SyncedIndicator = ({selected = false}: {selected?: boolean}) => {
  return (
    <svg
      width="30"
      height="18"
      viewBox="0 0 30 18"
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        selected ? 'fill-primary-100' : 'fill-[currentColor]'
      } w-4 inline-flex align-text-bottom`}
    >
      <path
        d="M6.70312 0H14.25C17.9531 0 21 3.04688 20.9531 6.75C20.9531 10.3125 18.2344 13.2656 14.6719 13.5H14.2969C13.6875 13.5469 13.125 13.0781 13.125 12.4688C13.0781 11.8594 13.5469 11.2969 14.1562 11.25H14.5312C16.875 11.1094 18.75 9.14062 18.75 6.75C18.75 4.26562 16.7344 2.25 14.25 2.25H6.70312C4.21875 2.25 2.25 4.26562 2.25 6.75C2.25 9.14062 4.07812 11.1094 6.42188 11.25L6.79688 11.2969C7.40625 11.2969 7.875 11.8594 7.82812 12.4688C7.82812 13.0781 7.26562 13.5469 6.65625 13.5H6.28125C2.71875 13.2656 0 10.3125 0 6.75C0 3.04688 3 0 6.70312 0ZM23.25 18H15.7031C12 18 9 15 9 11.2969C9 7.73438 11.7188 4.78125 15.2812 4.54688L15.6094 4.5C16.2188 4.5 16.7812 4.92188 16.7812 5.57812C16.8281 6.1875 16.3594 6.70312 15.75 6.75L15.4219 6.79688C13.0781 6.9375 11.25 8.90625 11.25 11.2969C11.25 13.7812 13.2188 15.75 15.7031 15.75H23.25C25.7344 15.75 27.75 13.7344 27.75 11.2969C27.75 8.90625 25.875 6.9375 23.5312 6.79688L23.1562 6.75C22.5469 6.75 22.0781 6.1875 22.125 5.57812C22.125 4.96875 22.6875 4.5 23.2969 4.54688H23.6719C27.2344 4.78125 30 7.73438 30 11.2969C30 15 26.9531 18 23.25 18Z"
        className="fill-current"
      />
    </svg>
  )
}
