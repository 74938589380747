import screenObj from '@fool/jester-design-dictionary/src/dictionary/screen.js'
import {useState, useEffect} from 'react'
import {useIsClient} from 'usehooks-ts'

export function isMobileSize(screenSize: string | undefined) {
  return screenSize === 'xs' || screenSize === 'sm' || screenSize === 'md'
}

export function isLargeSize(screenSize: string | undefined) {
  return screenSize === 'lg'
}

export function getCurrentScreen() {
  if (typeof window === 'undefined' || !window.screen) return undefined

  const em = parseFloat(getComputedStyle(document.documentElement).fontSize)
  const screenWidth = window.innerWidth / em
  let currentScreen = null

  // Sorting
  const screens: {[key: string]: string} = screenObj.screens
  const screenSizes: {[key: string]: string} = Object.entries(screens)
    .sort(([, a], [, b]) => parseFloat(a as string) - parseFloat(b as string))
    .reduce((obj: {[key: string]: string}, [key, value]) => {
      obj[key] = value
      return obj
    }, {})

  for (const screen in screenSizes) {
    const a = parseInt(screenSizes[screen].replace('em', ''), 10)
    if (screenWidth < a) {
      currentScreen = screen
      break
    }
  }

  if (!currentScreen) {
    const screenKeys = Object.keys(screenSizes)
    currentScreen = screenKeys[screenKeys.length - 1]
  }
  return currentScreen
}

const useScreenSize = () => {
  const isClient = useIsClient()
  const [currentScreen, setCurrentScreen] = useState<string | undefined>(
    undefined,
  )

  useEffect(() => {
    if (!isClient) {
      return
    }

    setCurrentScreen(getCurrentScreen())

    const handleResize = () => {
      const newScreen = getCurrentScreen()
      if (newScreen !== currentScreen) {
        setCurrentScreen(newScreen)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isClient, currentScreen])

  return currentScreen
}

export default useScreenSize
