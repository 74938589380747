import {ReactNode} from 'react'
import type {Numeric} from '~types/scorecards'
import Tooltip from '~components/tooltip/tooltip'

const MILLION = 1000000
const BILLION = 1000000000
const TRILLION = 1000000000000

type ColoredChangeProps = {
  change: Numeric | number | null | undefined
  truncateAt?: number
  decimals?: number
  className?: string
  currency?: 'USD'
}

function displayTruncatedWithCommas(
  formattedNumber: string,
  truncatedNumber: string,
) {
  for (let i = 0; i < formattedNumber.length; i++) {
    if (truncatedNumber[i] && truncatedNumber[i] !== formattedNumber[i]) {
      let truncatedSplit: string | string[] = truncatedNumber.split(
        '',
      ) as string[]
      truncatedSplit.splice(i, 0, formattedNumber[i]) as string[]
      truncatedSplit = truncatedSplit.join('') as string
      truncatedNumber = truncatedSplit
    }
  }

  return truncatedNumber
}

function truncateNumber(
  number: number,
  numberLength: number,
  prefix?: string,
  suffix?: string,
) {
  let formattedNumber = number.toLocaleString('en-US', {
    minimumFractionDigits: 2,
  })
  let returnValue

  if (number >= 1_000_000_000_000) {
    formattedNumber = (number / 1_000_000_000_000).toFixed(2) + 'T'
    returnValue = formattedNumber
  } else if (number >= 1_000_000_000) {
    formattedNumber = (number / 1_000_000_000).toFixed(2) + 'B'
    returnValue = formattedNumber
  } else if (number >= 1_000_000) {
    formattedNumber = (number / 1_000_000).toFixed(2) + 'M'
    returnValue = formattedNumber
  } else if (number >= 10_000) {
    formattedNumber = (number / 1_000).toFixed(2) + 'K'
    returnValue = formattedNumber
  } else {
    const numberTruncated = number.toString().slice(0, numberLength)
    returnValue = displayTruncatedWithCommas(formattedNumber, numberTruncated)
  }

  return (
    <>
      <Tooltip
        text={formatOriginalNumber(number, prefix, suffix)}
        placement="right"
        offset={[18, -105]}
      >
        {prefix ? prefix : ''}
        {returnValue}
        {suffix ? suffix : ''}
      </Tooltip>
    </>
  )
}

export function formatPrice(
  price: number | undefined,
  currency: string = 'USD',
  truncateAt: number = 8,
): ReactNode {
  let displayPrice
  const currencySymbol = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  })
    .format(0)
    .replace(/[0-9.,]/g, '')
    .trim()

  function setPriceFormatting(price: number) {
    const formattedPrice = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(price)

    return `${formattedPrice}`
  }

  if (price !== undefined) {
    displayPrice =
      price.toFixed(2).toString().length > truncateAt + 1
        ? truncateNumber(price, truncateAt, currencySymbol)
        : setPriceFormatting(price)
  }

  return <>{price === undefined ? '-' : displayPrice}</>
}

export function formatOriginalNumber(
  price: number | undefined,
  prefix: string = '',
  suffix: string = '',
): string {
  if (price === undefined) return '-'
  const formattedNumber = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(price)

  return `${prefix}${formattedNumber}${suffix}`
}

const formatValueWithCommas = (value: number, decimalValue: number) => {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: decimalValue,
    minimumFractionDigits: decimalValue,
  }).format(value)
}

export function ColoredChange({
  change,
  truncateAt = 8,
  decimals = 2,
  className,
}: ColoredChangeProps): ReactNode {
  if (change === null || change === undefined) return <span>-</span>
  const changeSign = change > 0 ? '+' : ''
  const value =
    change.toFixed(2).toString().length > truncateAt + 1
      ? truncateNumber(change, truncateAt, changeSign, '%')
      : change
        ? `${changeSign}${formatValueWithCommas(change, decimals)}%`
        : null
  const isPositive = change > 0
  return (
    <span
      className={`font-bold ${
        value
          ? isPositive
            ? 'text-secondary-green-80'
            : 'text-secondary-red-50'
          : ''
      } ${className}`}
    >
      {value ? value : '-'}
    </span>
  )
}

export function PriceColoredChange({
  change,
  className = '',
  truncateAt = 8,
  currency = 'USD',
}: ColoredChangeProps): ReactNode {
  if (change === null || change === undefined) return <span>-</span>
  const price = formatPrice(change, currency, truncateAt)
  const isPositive = change > 0
  return (
    <span
      className={`font-bold ${
        change
          ? isPositive
            ? 'text-secondary-green-80'
            : 'text-secondary-red-50'
          : ''
      } ${className}`}
    >
      {price ? price : '-'}
    </span>
  )
}

export function roundToTwo(num: number): number {
  return +(Math.round(Number(num + 'e+2')) + 'e-2')
}

export function formatMarketCap(
  value: number,
  denominator = false,
  currency = 'USD',
  decimals = 2,
): string {
  if (denominator) {
    if (denominator.toString().toLowerCase() === 'millions') {
      value = value * MILLION
    } else if (denominator.toString().toLowerCase() === 'billions') {
      value = value * BILLION
    } else {
      value = value * TRILLION
    }
  }
  let shorthandCap = 0
  let abbr = ''
  if (value > TRILLION) {
    shorthandCap = value / TRILLION
    abbr = 'T'
  } else if (value > BILLION) {
    shorthandCap = value / BILLION
    abbr = 'B'
  } else {
    shorthandCap = value / MILLION
    abbr = 'M'
  }
  let decimal = shorthandCap / 10 > 1 ? 0 : 1
  if (decimals) {
    decimal = decimals // override (decimals=0 should work)
  }
  try {
    return (
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        maximumFractionDigits: decimal,
      }).format(shorthandCap) + abbr
    )
  } catch (e) {
    return ''
  }
}

export function getMarketCapClass(marketCap: number) {
  if (marketCap > 20000000000) return 'Large-cap'
  if (marketCap > 2000000000) return 'Mid-cap'
  if (marketCap > 250000000) return 'Small-cap'
  return 'Micro-cap'
}

export const percentage = (
  value: number,
  decimalPlaces = 1,
  options = {baseNumber: 100},
) => {
  if (isNaN(value)) {
    console.warn(
      `Error: value "${value}" passed into percentage() is not a valid number`,
    )
    return value
  }
  const num =
    Math.round(value * options.baseNumber * Math.pow(10, decimalPlaces)) /
    Math.pow(10, decimalPlaces)
  return num
}

export function formatPercent(
  value: number | null,
  multi100 = true,
): string | null {
  if (value === null) {
    return null
  }
  return `${(multi100 ? value * 100 : value).toFixed(2)}%`
}

export function convertStringToNumber(value: string): number | null {
  const numberValue = Number(value)
  if (Number.isNaN(numberValue)) {
    return null
  }
  return numberValue
}

export function numberWithCommas(value: number | null): string {
  if (value === null) return '-'
  return value.toLocaleString('en-US')
}

export function formatBeta(value: number | null): string {
  if (value === null) return '-'
  let label = 'Average'
  if (value > 1.2) label = 'High'
  if (value < 1) label = 'Low'

  return `${value.toFixed(2)} (${label})`
}

export function formatNumberForCharts(value: number): string {
  if (!value) {
    return '-'
  } else if (value <= 1 && value >= -1) {
    return (value * 100).toFixed(0) + '%'
  } else if (Math.abs(value) < 1_000) {
    return value.toFixed(0)
  } else if (Math.abs(value) < 1_000_000) {
    return (value / 1_000).toFixed(0) + 'K'
  } else if (Math.abs(value) < 1_000_000_000) {
    return (value / 1_000_000).toFixed(0) + 'M'
  } else if (Math.abs(value) < 1_000_000_000_000) {
    return (value / 1_000_000_000).toFixed(0) + 'B'
  } else {
    return (value / 1_000_000_000_000).toFixed(0) + 'T'
  }
}

export const US_EXCHANGES = [
  'NYSE',
  'NASDAQ',
  'NASDAQOTH',
  'NYSEMKT',
  'NASDAQMUTFUND',
]

export const markLogo = (ticker: string, exchange: string) => {
  const imgPath = US_EXCHANGES.includes(exchange)
    ? ticker
    : `${exchange}_${ticker}?exchange=true`
  return `//g.foolcdn.com/art/companylogos/mark/${imgPath}.png`
}

export const truncateDecimals = (n: number, toDecimalPlace: number) => {
  if (Math.floor(n) === n) return n
  const o = Math.pow(
    10,
    Math.min(toDecimalPlace, n.toString().split('.')[1].length || 0),
  )
  return Math.trunc(n * o) / o
}

export const roundTo = (n: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0)
  return Math.round(n * multiplier) / multiplier
}
export const largeNumberShorthand = (
  n: number,
  decimals: number = 2,
): string | number => {
  if (!n || !isFinite(n)) return n

  const zeros: number = Math.log10(n)

  // We limit to 3 places since we don't yet handle trillions
  const places: number = Math.min(3, Math.floor(zeros / 3))
  const short: number = n / Math.pow(10, places * 3)

  return (
    numberWithCommas(Number(short.toFixed(decimals))) +
    ['', 'M', 'MM', 'B'][places]
  )
}

export const isNumber = (val: number) => {
  return !isNaN(val) && Number.isFinite(val)
}

export const getToday = () => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  }
  const today = new Date()
  return {
    datetime: today.toISOString(),
    date: today.toLocaleDateString('en-US', options),
  }
}

export const liveUrl = function (url: string) {
  return url.replace('staging.', '')
}

export const toHHMMSS = function (seconds: number) {
  return new Date(seconds * 1000).toISOString().substr(11, 8)
}

export const percent = function (
  value: number,
  precision: number,
  addNumberPrefix: boolean,
) {
  if (isNaN(value)) return '0.00%'
  let valueInPercent = Math.round(value * 10000) / 100
  if (precision) {
    valueInPercent = roundTo(valueInPercent, precision)
  }
  const val: string = valueInPercent.toFixed(2)
  const prefix = addNumberPrefix ? numberPrefix(valueInPercent) : ''
  return `${prefix}${val}%`
}

export const numberPrefix = function (value: number) {
  return +value > 0 ? '+' : ''
}

export const assetClass = function (marketCap: number) {
  if (marketCap > 200 * BILLION) {
    return 'Mega Cap'
  } else if (marketCap > 10 * BILLION) {
    return 'Large Cap'
  } else if (marketCap > 2 * BILLION) {
    return 'Mid Cap'
  } else if (marketCap > 300 * MILLION) {
    return 'Small Cap'
  } else if (marketCap > 50 * MILLION) {
    return 'Micro Cap'
  } else if (marketCap > 0) {
    return ''
  }
}

export const marketCap = function (
  value: number,
  denominator: string | false = false,
  currency: string = 'USD',
  decimals?: number,
): string {
  const MILLION = 1e6
  const BILLION = 1e9
  const TRILLION = 1e12

  if (denominator) {
    if (denominator.toLowerCase() === 'millions') {
      value = value * MILLION
    } else if (denominator.toLowerCase() === 'billions') {
      value = value * BILLION
    } else {
      value = value * TRILLION
    }
  }

  let shorthandCap = 0
  let abbr = ''
  if (value > TRILLION) {
    shorthandCap = value / TRILLION
    abbr = 'T'
  } else if (value > BILLION) {
    shorthandCap = value / BILLION
    abbr = 'B'
  } else {
    shorthandCap = value / MILLION
    abbr = 'M'
  }

  let decimal = shorthandCap / 10 > 1 ? 0 : 1
  if (decimals !== undefined) {
    decimal = decimals // override (decimals=0 should work)
  }

  try {
    return (
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        maximumFractionDigits: decimal,
      }).format(shorthandCap) + abbr
    )
  } catch (e) {
    return ''
  }
}

// returns Q1, Q2, Q3, Q4 from a date string
export const formatQuarterlyDate = function (
  date_string: string | undefined,
): string {
  if (!date_string) return ''
  const date = new Date(date_string)
  const month: number = date.getMonth()
  const year: number = date.getFullYear()
  const quarter: number = Math.floor((month + 3) / 3)

  return `Q${quarter} ${year}`
}

// returns just the year number from a date string
export const formatAnnualDate = function (date_string: string): string {
  const date = new Date(date_string)
  const year: number = date.getFullYear()
  return `${year}`
}

export const formatPercentForFilter = (number: number) => number / 100

export function utcDate(date: string) {
  const localDate = new Date(date)
  return Date.UTC(
    localDate.getFullYear(),
    localDate.getMonth(),
    localDate.getDate(),
    localDate.getHours(),
    localDate.getMinutes(),
  )
}

export const formatPriceAsOf = (date: string) => {
  // Return date in the following format Sep 26 at 9:00 PM EDT.
  return new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/New_York',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
  }).format(utcDate(date))
}
