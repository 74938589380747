'use client'
import {useState, useEffect, useMemo} from 'react'
import {useIdleTimer} from 'react-idle-timer'
import {Maybe} from '~types/__generated__/graphql'
import type {RealtimeQuotes, RealtimeQuotesResponse} from '~types/my-stocks'
import {fetchRealtimeQuotes} from '~app/(main)/my-stocks/utils/index'
import {getQuotesData} from '~data/api/instruments'
import useDebounce from '~utils/useDebounce'
import useInterval from '~utils/useInterval'
import {createInstrumentIdBatch} from '~utils/batch'
import {useSession} from 'next-auth/react'

interface QuoteData {
  instrumentId: number // ✅ Unique stock identifier
  currencyCode: string // ✅ Currency (e.g., "USD")
  price: number // ✅ Current stock price
  priceChange: number // ✅ Absolute change in price
  change: number | null // ✅ Percentage change (can be null)
  lastTradeDate: string // ✅ Timestamp of last trade
}

/**
 * BTC is frequently used along realtime data (see ticker tape bars on home/my-stocks page. To ensure BTC data stays in sync we need to continuously fetch from quotes.
 * This is obviously slower than realtime but will keep from different BTC price data showing in the Ticker Tape and My Stocks table for example.
 */
const BTC_INSTRUMENT_ID = '343539'

/**
 * Convert both the current time and the opening time to minutes since midnight for correct comparison.
 * This avoids issues with fractional hours.
 * The market is considered open if the current time is greater than or equal to 9:30 AM (which is 9 * 60 + 30 minutes) and less than or equal to the closing time converted to minutes.
 */

/*
 * Note:
 * The original approach compared the integer value from getHours() to 9.5.
 * However, since getHours() always returns an integer (0-23), using 9.5
 * to represent 9:30 AM is imprecise. For example, at 9:30 AM, getHours() returns 9,
 * which is less than 9.5, incorrectly suggesting the market isn't open.
 * Instead, converting the entire time to minutes since midnight provides an accurate check.
 */

export function isStockMarketOpen(closingTime = 16) {
  const nyTimeZone = 'America/New_York'
  const now = new Date(
    new Date().toLocaleString('en-US', {timeZone: nyTimeZone}),
  )
  const currentMinutes = now.getHours() * 60 + now.getMinutes()
  const marketOpenMinutes = 9 * 60 + 30 // 9:30 AM
  const marketCloseMinutes = closingTime * 60

  return (
    currentMinutes >= marketOpenMinutes && currentMinutes <= marketCloseMinutes
  )
}

type RealtimeQuotesState = {
  realtimeQuotes: {
    quotes: RealtimeQuotesResponse
  }
}
interface RealtimeQuotesOptions {
  instrumentIds: number[]
  interval?: number
}

function useRealtimeQuotes({
  timeout = 5000,
  interval = 3800,
  debounceInterval = 1000,
  batchSize = 50,
  quotesFallback = false,
}: {
  timeout?: number
  interval?: number
  debounceInterval?: number
  batchSize?: number
  quotesFallback?: boolean
} = {}): [
  Maybe<RealtimeQuotesResponse>,
  (options: RealtimeQuotesOptions) => void,
] {
  const [realtimeQuotes, setRealtimeQuotes] =
    useState<Maybe<RealtimeQuotesResponse>>(null)
  const [activeInterval, setActiveInterval] = useState<number | null>(interval)
  const [batches, setBatches] = useState<string[] | undefined>(undefined)
  const [activeIds, setActiveIds] = useState<number[] | undefined>(undefined)
  const session = useSession()

  const {start, reset} = useIdleTimer({
    startManually: true,
    timeout,
    crossTab: true,
    onIdle: () => {
      setActiveInterval(null)
    },
  })

  // Improve performance by memoizing the price change indicator calculation
  const calculatePriceChangeIndicator = useMemo(
    () => (prevPrice: number | null | undefined, currentPrice: number) => {
      if (
        prevPrice !== null &&
        prevPrice !== undefined &&
        currentPrice > prevPrice
      )
        return 'positive'
      if (
        prevPrice !== null &&
        prevPrice !== undefined &&
        currentPrice < prevPrice
      )
        return 'negative'
      return null
    },
    [],
  )

  const fetchData = async (batches: string[]) => {
    try {
      if (!batches) return

      // BTC is not real time, this avoids partial errors when BTC is present
      const batchesWithoutBTC = batches.map((batch) =>
        batch
          .split(',')
          .filter((id) => id !== BTC_INSTRUMENT_ID)
          .join(','),
      )

      if (batchesWithoutBTC[0] === '') return

      // does the batches string include BTC_INSTRUMENT_ID
      const includesBTC = batches.some((batch) =>
        batch.includes(BTC_INSTRUMENT_ID),
      )

      const realtimeQuotesArray = await Promise.all(
        batchesWithoutBTC.map((queryString) =>
          fetchRealtimeQuotes(queryString, session?.data),
        ),
      )

      // Before, using a forEach, every loop iteration creates a new object for quotes, which is computationally expensive.
      const {quotes, errors} = realtimeQuotesArray.reduce(
        (acc, obj: RealtimeQuotes) => {
          if (!obj.quotes) return acc
          // Efficiently modifies `acc.quotes` in-place
          Object.entries(obj.quotes).forEach(([quoteId, quoteData]) => {
            acc.quotes[Number(quoteId)] = {
              instrumentId: Number(quoteId), // Ensure numeric ID
              currencyCode: quoteData.CurrencyCode,
              price: quoteData.current_price,
              priceChange: quoteData.change,
              change: quoteData.percent_change
                ? Math.round((quoteData.percent_change || 0) * 10000) / 100
                : null,
              lastTradeDate: quoteData.last_trade_date,
            }
          })

          Object.assign(acc.errors, obj.errors) // Merge errors efficiently

          return acc
        },
        {
          quotes: {} as Record<number, QuoteData>,
          errors: {} as Record<number, {message: string}>,
        }, // Define initial types
      )

      // Ensure BTC quote is included if needed
      if (includesBTC) {
        const btcQuote = await getQuotesData([BTC_INSTRUMENT_ID], session?.data)
        Object.assign(quotes, btcQuote)
      }
      return {realtimeQuotes: {quotes, errors}}
    } catch (error) {
      console.error('Error during fetch:', error)
      throw error
    }
  }

  const transformRealtimeQuoteData = (
    prevRealtimeQuotes: Maybe<RealtimeQuotesResponse>,
    quote: RealtimeQuotesState,
  ) => {
    const instruments: RealtimeQuotesResponse = {}
    const quoteValues = quote?.realtimeQuotes?.quotes

    if (prevRealtimeQuotes && quoteValues) {
      Object.entries(quoteValues).forEach(([key, value]) => {
        const prevQuote = (prevRealtimeQuotes as RealtimeQuotesResponse)[key]
        const valuePrice = (value as Record<string, unknown>).price

        const priceChangeIndicator = calculatePriceChangeIndicator(
          prevQuote?.price,
          valuePrice as number,
        )

        instruments[key] = {
          ...(value && typeof value === 'object'
            ? (value as Record<string, unknown>)
            : {}),
          indicator: priceChangeIndicator,
        }
      })
    }

    return {
      ...(prevRealtimeQuotes ?? {}),
      ...instruments,
    }
  }

  const getIdsHandler = useDebounce((ids) => {
    if (!ids) {
      return
    }

    const activeIds = [...ids]
    const newBatches = activeIds.length
      ? createInstrumentIdBatch(activeIds, batchSize)
      : []

    if (newBatches.length > 0) {
      setBatches(newBatches)
    }

    if (!isStockMarketOpen()) {
      setActiveInterval(null)
    } else {
      setActiveInterval(interval)
    }

    fetchData(newBatches).then((quote) => {
      if (quote) {
        setRealtimeQuotes((prevRealtimeQuotes) => {
          const q = transformRealtimeQuoteData(prevRealtimeQuotes, quote)
          return prevRealtimeQuotes !== q ? q : prevRealtimeQuotes
        })
      }
    })
  }, debounceInterval)

  useInterval(async () => {
    if (activeIds && activeIds.length) getIdsHandler(activeIds)
  }, activeInterval)

  // Only update activeInterval if the value is different to prevent unnecessary renders:
  useEffect(() => {
    if (batches && batches.length) {
      if (isStockMarketOpen()) {
        start()
        setActiveInterval((prev) => (prev !== interval ? interval : prev))
      }
      return () => {
        reset()
        setActiveInterval((prev) => (prev !== null ? null : prev))
      }
    }
  }, [batches])

  useEffect(() => {
    const fetchQuote = async () => {
      if (activeIds && activeIds.length) {
        const ids = [...activeIds]
        const batches: string[] | [] =
          ids && ids.length ? createInstrumentIdBatch(ids, batchSize) : []
        if (quotesFallback) {
          const quotes = await getQuotesData(batches, session?.data)
          if (quotes) setRealtimeQuotes(() => quotes)
        }
      }
    }
    fetchQuote()
  }, [activeIds])

  const getRealtimeQuotes = async (options: RealtimeQuotesOptions) => {
    if (!options) return
    const instrumentIds = options.instrumentIds
    if (instrumentIds && instrumentIds.length) {
      const ids = [...instrumentIds]
      setActiveIds(() => ids)
    }
  }
  return [realtimeQuotes, getRealtimeQuotes]
}

export default useRealtimeQuotes
