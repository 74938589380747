import {gql} from '~types/__generated__/gql'

export const FUTURES_QUERY = gql(`
  query Futures {
    futures {
      exchange
      baseSymbol
      quote {
        last
        percentChange
      }
    }
  }
`)
