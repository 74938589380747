import {JSX} from 'react'

type CardProps = {
  shadow?: string //medium or large
  bgColor?: string
  padding?: string
  rounded?: string
  children: React.ReactNode
  className?: string
  clickable?: boolean
} & JSX.IntrinsicElements['div']

function Card({
  shadow = 'none',
  bgColor = 'bg-primary-0',
  padding = 'p-24px',
  rounded = 'rounded-[8px]',
  children,
  className = '',
  style,
  clickable = true,
}: CardProps) {
  return (
    <div
      className={`${
        shadow === 'large'
          ? 'shadow-large'
          : shadow === 'medium'
            ? 'shadow-medium'
            : ''
      } ${
        shadow !== 'none' && clickable ? 'hover:shadow-large-hover' : ''
      } ${rounded} ${padding} ${bgColor} ${className}`}
      style={style}
    >
      {children}
    </div>
  )
}

export default Card
