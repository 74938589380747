import {Records, ScreenerRow} from '~types/rankings'

const createInstrumentIdBatch = (instrumentIds: number[], batchSize = 50) => {
  const ids = [...instrumentIds]
  const batches: string[] = []
  while (ids && ids.length > 0) {
    const batch = ids.splice(0, batchSize).toString()
    // let i = instrumentIds.replaceAll(',', ';')
    if (batch?.length > 0) batches.push(batch)
  }
  return batches
}

const getRankingsRecords = async (
  query: (offset: number) => Promise<{screenerRows: ScreenerRow} | undefined>,
  batchSize: number = 50,
): Promise<Records> => {
  const numberOfBatches = 1
  const rankingsData = []
  const offset = 0
  let data

  for (let i = 0; i < numberOfBatches; i++) {
    const currentOffset = offset + i * batchSize
    rankingsData.push(query(currentOffset))
  }

  await Promise.all(rankingsData)
    .then((results) => {
      if (results) {
        const mergedResults = results.reduce(
          (acc: Records | [], batchResult) => {
            const records = batchResult?.screenerRows?.records

            if (records) {
              acc = [...acc, ...records]
            }
            return acc
          },
          [],
        )
        data = mergedResults
      }
    })
    .catch((error) => {
      console.error('Error fetching batches:', error)
    })
  return data ? data : []
}

export {createInstrumentIdBatch, getRankingsRecords}
