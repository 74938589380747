import {RootState} from '~data/client/store'
import {createSelector} from '@reduxjs/toolkit'
import {MY_STOCKS_SLUG, STATUS_STATES} from '~data/constants'
import {MissingTransactions, Portfolio} from '~types/my-stocks'

/**
 *
 * Base entities
 */
export const selectPortfolios = (state: RootState) => state.myStocks.portfolios
export const selectSubnavPortfolios = (state: RootState) =>
  state.myStocks.subnavPortfolios
export const selectAllPortfoliosMissingTransactions = (state: RootState) =>
  state.myStocks.allPortfoliosMissingTransactions
export const selectActivePortfolioUuid = (state: RootState) =>
  state.myStocks.activePortfolioUuid
export const selectWatchlistHoldings = (state: RootState) =>
  state.myStocks.watchlistHoldings
export const selectPortfolioSummaryLookup = (state: RootState) =>
  state.myStocks.portfolioSummaryLookup
// TODO: Move this to a routes selector file at some point
export const selectCurrentRoute = (state: RootState) => state.route.currentRoute
export const selectTransactionsLookup = (state: RootState) =>
  state.myStocks.transactionsLookup

/**
 *
 * Initial Loading States
 */
export const selectInitialHoldingsLoaded = (state: RootState) =>
  state.myStocks.initialHoldingsLoad

/**
 *
 * Query Statuses
 */
export const selectGetHoldingStatus = (state: RootState) =>
  state.myStocks.getHoldingsStatus

export const selectMissingTransactionsStatus = (state: RootState) =>
  state.myStocks.getMissingTransactionsStatus

export const selectPortfolioStatus = (state: RootState) => {
  return state.myStocks.getPortfolioStatus
}

export const selectTransactionStatus = (state: RootState) => {
  return state.myStocks.getTransactionStatus
}

/**
 *
 * Memoized logic of statuses
 */
export const selectActivePortfolio = createSelector(
  [selectPortfolios, selectActivePortfolioUuid],
  (selectPortfolios, selectActivePortfolioUuid) => {
    return (
      selectPortfolios?.find(
        (portfolio: Portfolio) => portfolio?.uuid === selectActivePortfolioUuid,
      ) ?? null
    )
  },
)

export const selectGetHoldingsIsSuccess = createSelector(
  [selectGetHoldingStatus, selectInitialHoldingsLoaded],
  (
    selectGetHoldingStatus: STATUS_STATES,
    selectInitialHoldingsLoaded: boolean,
  ) => {
    return (
      ((selectInitialHoldingsLoaded && selectInitialHoldingsLoaded) ||
        !selectInitialHoldingsLoaded) &&
      selectGetHoldingStatus === STATUS_STATES.SUCCEEDED
    )
  },
)

export const selectActivePortfolioSummary = createSelector(
  [selectPortfolioSummaryLookup, selectActivePortfolioUuid],
  (selectPortfolioSummaryLookup, selectActivePortfolioUuid) => {
    if (!selectActivePortfolioUuid) return null
    return selectPortfolioSummaryLookup[selectActivePortfolioUuid]
  },
)

export const selectMissingTransactionsByPortfolio = (
  portfolioUuid: string | null,
) => {
  return createSelector(
    [
      selectAllPortfoliosMissingTransactions,
      selectActivePortfolioUuid,
      selectActivePortfolio,
    ],
    (
      selectAllPortfoliosMissingTransactions,
      selectActivePortfolioUuid,
      selectActivePortfolio,
    ) => {
      let missingTransactions: MissingTransactions[] | [] = []
      const allMissingTransactions =
        selectAllPortfoliosMissingTransactions || []
      const currentActivePortfolioUuid =
        selectActivePortfolioUuid || portfolioUuid
      const activePortfolio = selectActivePortfolio
      const activePortfolioName = activePortfolio?.name
        ? activePortfolio.name
        : 'All Stocks'
      if (
        currentActivePortfolioUuid !== 'all-stocks' &&
        currentActivePortfolioUuid !== null &&
        allMissingTransactions !== null
      ) {
        missingTransactions = allMissingTransactions?.filter(
          (holdings: MissingTransactions) => {
            return holdings?.accountUuid === currentActivePortfolioUuid
          },
        )
        missingTransactions = missingTransactions.map((item) => {
          return {...item, portfolio: activePortfolioName}
        })
        return missingTransactions
      } else if (allMissingTransactions) {
        return allMissingTransactions
      }
      return null
    },
  )
}

// Temp Route Selector Stuff
export const selectIsMyStocksRoute = (state: RootState) => {
  return !!(
    state.route.currentRoute &&
    state.route.currentRoute.includes(`${MY_STOCKS_SLUG}`)
  )
}

// Get the current transactions based on the active portfolio uuid
export const selectCurrentTransactions = createSelector(
  [selectTransactionsLookup, selectActivePortfolioUuid],
  (selectTransactionsLookup, selectActivePortfolioUuid) => {
    if (selectActivePortfolioUuid) {
      return (
        selectTransactionsLookup[selectActivePortfolioUuid]?.transactions || []
      )
    }
  },
)

// Get the total number of transactions pages for the active portfolio
// Endpoint gets 50 transactions per page
export const selectCurrentTransactionsTotalPages = createSelector(
  [selectTransactionsLookup, selectActivePortfolioUuid],
  (selectTransactionsLookup, selectActivePortfolioUuid) => {
    if (selectActivePortfolioUuid) {
      return (
        selectTransactionsLookup[selectActivePortfolioUuid]
          ?.transactionsTotalPages || 1
      )
    }
  },
)

// Get the current page number for the transactions endpoint bsaed on the active portfolio
export const selectCurrentTransactionsPageNumber = createSelector(
  [selectTransactionsLookup, selectActivePortfolioUuid],
  (selectTransactionsLookup, selectActivePortfolioUuid) => {
    if (selectActivePortfolioUuid) {
      return (
        selectTransactionsLookup[selectActivePortfolioUuid]?.currentPage || 1
      )
    }
  },
)

// Determine if there are transactions for the active portfolio
export const selectHasTransactions = createSelector(
  [selectTransactionsLookup, selectActivePortfolioUuid],
  (selectTransactionsLookup, selectActivePortfolioUuid) => {
    if (selectActivePortfolioUuid) {
      return selectTransactionsLookup[selectActivePortfolioUuid]
        ?.hasTransactions
        ? selectTransactionsLookup[selectActivePortfolioUuid].hasTransactions
        : false
    }
  },
)
