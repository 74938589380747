import React, {useState, useEffect} from 'react'
import classNames from 'classnames'

const delay = 1000

const Indicator = ({
  children,
  indicator,
}: {
  children: React.ReactNode
  indicator: 'positive' | 'negative' | null | undefined
}) => {
  const [activeIndicator, setActiveIndicator] = useState(indicator)
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (intervalId) clearInterval(intervalId)
    setActiveIndicator(() => indicator)

    function tick() {
      setActiveIndicator(() => null)
    }

    const id = setInterval(tick, delay)
    setIntervalId(id)

    return () => {
      clearInterval(id)
    }
  }, [indicator])

  return (
    <span
      className={classNames(
        activeIndicator === 'positive' && 'bg-[#e6f7ea]',
        activeIndicator === 'negative' && 'bg-[#f7e6e6]',
        'px-[4px] rounded-sm',
      )}
    >
      {children}
    </span>
  )
}

export default Indicator
