'use client'
import {useRouter} from 'next/navigation'
import {useEffect, useCallback} from 'react'

interface UseScrollToCoverageProps<T> {
  coverageRef: React.RefObject<HTMLElement>
  dependencies?: readonly T[]
}

function debugLog(message: string) {
  if (process.env.NODE_ENV === 'development') {
    console.log(message)
  }
}

const useRouteToPath = () => {
  const router = useRouter()
  const routeToPath = useCallback(
    // path is the route we want to navigate to
    // origin where the navigation is coming from to help better debug the source of routing
    async (path: string, origin?: string): Promise<void> => {
      debugLog(`Route origin: ${origin}`)
      try {
        // TODO: the await on router.push probably isn't actually doing what the
        // original code intended (i.e. seems to resolve when navigation
        // _starts_ and not when it completes; or, maybe it isn't doing
        // anything)

        // Navigate and wait for it to complete
        await router.push(path)
        // Wait for the next tick to ensure navigation has started
        await new Promise((resolve) => setTimeout(resolve, 0))
      } catch (error) {
        console.error('Navigation failed:', error)
      }
    },
    [router],
  )
  return routeToPath
}

// This is to ensure if content, which are loading async,
// takes up the viewport, the user is still sent to the coverage
// section once its mounted if the anchor tag is present
export function useScrollToCoverage<T>({
  coverageRef,
  dependencies = [],
}: UseScrollToCoverageProps<T>) {
  useEffect(() => {
    if (!window) return
    setTimeout(() => {
      if (!coverageRef.current?.getBoundingClientRect().top) return
      if (dependencies.some((dep: T) => !dep)) return

      // Check if coverage is already mostly on the screen
      const isAlreadyInViewport = () =>
        (coverageRef.current?.getBoundingClientRect().top || 0) >
          window.scrollY &&
        (coverageRef.current?.getBoundingClientRect().top || 0)
      window.scrollY + window.innerHeight / 2

      const hash = window.location.hash.replace('#', '')
      if (coverageRef.current && coverageRef.current.id === hash) {
        const stickyHeaderHeight =
          document.getElementById('layout-sticky-header')?.clientHeight || 0
        const offsetPosition =
          coverageRef.current.getBoundingClientRect().top +
          window.scrollY -
          stickyHeaderHeight

        if (isAlreadyInViewport()) return

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      }
    }, 1000)
  }, [coverageRef.current, ...dependencies])
}

export {useRouteToPath}
