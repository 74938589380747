import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {MY_STOCKS_FORM_TYPE} from '~data/constants'

export type ChildData = {
  [key: string]: string | boolean | number | unknown | unknown[]
}

export type ModalAfterNavigationConfig = {
  path: string
  origin?: string
  modalConfig: {
    formType:
      | (typeof MY_STOCKS_FORM_TYPE)[keyof typeof MY_STOCKS_FORM_TYPE]
      | null
  }
} | null

export type ModalState = {
  showModal: boolean
  childData?: ChildData
  /**
   * Coordinates modal display with Next.js parallel route navigation.
   * Required because parallel route layouts render independently,
   * which can cause race conditions between navigation and modal state.
   *
   * Ensures sequence: close modal -> navigate -> show new modal
   */
  modalAfterNavigation: ModalAfterNavigationConfig
}

const initialState: ModalState = {
  showModal: false,
  childData: {},
  modalAfterNavigation: null,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    modalShowState: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload
    },
    modalDataState: (state, action: PayloadAction<ChildData>) => {
      state.childData = action.payload
    },
    setModalAfterNavigation: (
      state,
      action: PayloadAction<ModalAfterNavigationConfig>,
    ) => {
      state.modalAfterNavigation = action.payload
    },
  },
})

export const {modalShowState, modalDataState, setModalAfterNavigation} =
  modalSlice.actions
export default modalSlice.reducer
